.appBtn {
  font-family: 'Montserrat';
  font-size: 1em;
  background-color: transparent;
  border: 0.5px solid black;
  border-radius: 4px;
  text-align: center;
}

.appBtn.brown {
  background-color: #99714b;
  color: white;
  border: none;
}

.flex {
  display: flex;
}

@media (max-width: 720px) {
  .appBtn {
    width: 100%;
  }
  .appBtn.brown {
    width: 100%;
  }
}

@media (min-width: 720px) {
  .appBtn {
    width: 190px;
  }
}

@media (max-width: 450px) {
  .btns {
    flex-direction: column;
    gap: 30px;
  }
}

@media (min-width: 450px) {
  .btns {
    flex-direction: row;
  }

  .appBtn + .appBtn {
    margin-left: 20px;
  }
}

.btns {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f2;
  padding: 70px 16px;
}

.banner h3 {
  font-size: 2em;
  margin: 0px;
  text-align: center;
}

.banner h4 {
  font-size: 1em;
  margin-top: 0px;
  margin-bottom: 30px;
  font-weight: 400;
}
