@keyframes mymove {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.mainContainer {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 200px;
  width: auto;
}

.BannerContainer {
  display: flex;
}

.logoBanner {
  display: flex;
  background-color: #ffffff;

  align-items: center;
  justify-content: space-around;
  gap: 20px;

  animation: 30s mymove infinite linear;
}

.logoContainer img {
  width: fit-content;
  object-fit: contain;
}

.logoContainer:first-child {
  width: 150px;
}
