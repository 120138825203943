.liveStream {
  /* height: 70vh; */
}

.streamContainer {
  /* 16:9 */
  margin: 0 auto;
  width: 100%;

  aspect-ratio: 16/9;
  overflow: hidden;
}

.streamContainer iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
