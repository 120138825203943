@media (max-width: 720px) {
  .mainContainer h1 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 720px) {
  .mainContainer {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.mainContainer {
  padding-top: 8em;
  display: flex;
  justify-content: center;
  flex-direction: column;

  background-image: url('/src/assets/images/hero-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 100px;
}

.mainContainer h1 {
  text-align: center;
  font-size: 4em;
  margin-top: 0px;
}
