.ourMission {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8em;
  padding-bottom: 50px;
  padding-left: 32px;
  padding-right: 32px;
}

.ourMission h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 3em;
  font-weight: 600;
  text-align: center;
}

.ourMission p {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.125em;
  max-width: 970px;
}
