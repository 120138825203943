.flex {
  display: flex;
}

.imageContainer img {
  object-fit: contain;
}

.btns {
  display: flex;
  gap: 16px;
  width: 100%;
}

.appBtn {
  font-family: 'Montserrat';

  background-color: transparent;
  border: 1px solid black;
  border-radius: 4px;
  transition: 0.3s;
}

.appBtn.brown {
  background-color: #99714b;
  color: white;
  border: none;
  border: 1px solid #99714b;
}

.appBtn.google {
  width: 13em;
  height: 4em;
  display: flex;
  gap: 5px;
  background-color: transparent;
  border: 1px solid #99714b;
  color: #99714b;
  text-transform: none;
  font-size: 0.8em;
}

.appBtn a {
  display: block;

  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.appBtn.brown a {
  color: white;
}

.appBtn:hover {
  background-color: #99714b;
  border: none;
  border: 1px solid #99714b;
}

.appBtn.brown:hover {
  background-color: transparent;
  border: 1px solid #99714b;
}

.appBtn.google:hover {
  background-color: #99714b;
  border: 1px solid #99714b;
  color: white;
}

.appBtn a:hover {
  color: white;
  text-decoration: none;
  border: none;
}

.appBtn.brown a:hover {
  color: #99714b;
  border: none;
}

/* section1 */

@media (max-width: 720px) {
  .section1 {
    flex-direction: column;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
  }

  .section1 > p {
    font-size: 1em;
    margin: 0px;
  }

  .section1 h1 {
    font-size: 2.5em;
  }

  .section1 h2 {
    margin-bottom: 30px;
  }

  .texContainer {
    padding-bottom: 0px;
  }
  .textContainer > * {
    text-align: center;
    margin-top: 0px;
  }

  .textContainer p:first-child {
    font-size: 1em;
  }

  .textContainer p:nth-child(2) {
    font-size: 0.8em;
  }

  .textTitle {
    font-size: 3.3em;
    font-weight: 600;
  }

  .time {
    justify-content: center;
    margin-bottom: 20px;
  }

  .time p {
    margin: 0px;
  }

  .btns {
    justify-content: center;
    gap: 15px;
    flex-direction: column;
    align-items: center;
  }

  .appBtn {
    font-size: 0.8em;
    width: 100%;
  }

  .appBtn a {
    font-size: 1.125em;
    padding: 16px 0px;
  }

  .imageContainer img {
    width: 100%;
  }
}

@media (min-width: 720px) {
  .section1 {
    gap: 7%;
    flex-direction: row;
  }

  .section1 p {
    font-size: 01.125rem;
  }

  .section1 h2 {
    margin-bottom: 10px;
  }

  .textContainer {
    max-width: 400px;
    padding-bottom: 28px;
  }

  .textContainer > * {
    text-align: start;
  }
  .textTitle {
    font-size: 2.5em;
  }
  .time {
    justify-content: start;
    margin-bottom: 0px;
  }

  .imageContainer img {
    width: 250px;
  }

  .btns {
    justify-content: start;
  }
  .appBtn {
    font-size: 1em;
  }

  .appBtn a {
    padding: 16px 24px;
    min-width: 200px;
  }
}

@media (min-width: 820px) {
  .textContainer {
    max-width: 450px;
  }
  .textTitle {
    font-size: 3em;
  }
  .imageContainer img {
    width: 300px;
  }
}

@media (min-width: 960px) {
  .textContainer {
    max-width: 450px;
  }
  .textTitle {
    font-size: 3em;
  }

  .imageContainer img {
    width: 400px;
  }
}

@media (min-width: 1115px) {
  .section1 {
    gap: 10px;
  }

  .imageContainer img {
    width: fit-content;
  }

  .textContainer {
    max-width: 600px;
  }

  .textTitle {
    font-size: 3.5em;
  }
}

@media (min-width: 1245px) {
  .section1 {
    gap: 195px;
  }

  .textTitle {
    font-size: 3em;
  }
}

.section1 {
  display: flex;
  justify-content: center;
  /* height: 80vh; */
  padding-top: 8em;
  padding-bottom: 50px;
}

.section1 h1 {
  margin: 0px;
}

.section1 h2 {
  font-weight: 200;
  margin-top: 0px;
}
.section1 button img {
  padding-right: 5px;
  position: relative;
  top: 3px;
}

.textContainer {
  color: #000000;
}

/* .textContainer p:nth-child(2) {
  font-weight: 200;
} */

.remark {
  font-weight: 200;
  margin-top: 0px;
  margin-bottom: 30px;
}

.eventTime {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventTime h3 {
  font-size: 2em;
}
.time {
  display: flex;
  align-items: center;
}

.time p {
  margin: 0px;
}

.time div {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.time div:first-child {
  border-right: 1px solid #ffb8bd;
  padding-right: 30px;
}

.time div:nth-child(2) {
  padding-left: 30px;
}

/* section our-mission */

@media (max-width: 720px) {
  .ourMission {
    padding-left: 16px;
    padding-right: 16px;
  }

  .ourMission .appBtn {
    width: auto;
  }

  .ourMission .appBtn a {
    padding: 16px 24px;
  }
}
@media (min-width: 720px) {
  .ourMission {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.ourMission {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ourMission h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 2.5rem;
  text-align: center;
}

.ourMission p {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1em;
}
