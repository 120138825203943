.contactPage {
  display: flex !important;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 0.6rem;
  padding: 10px;
  margin: 0 auto;
  background-image: url('/src/assets/images/hero-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  /* min-height: 100vh; */
}

.contactPage h1 {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 20px;
}

.contactPage h2 {
  margin-top: 0px;
  font-weight: 400;
}

form {
  margin-bottom: 100px !important;
}
