@media (max-width: 515px) {
  .privacyContainer {
    text-align: center;
    align-items: center;
  }
  .privacyContainer h1 {
    font-size: 3em;
    font-weight: 600;
  }
}

@media (min-width: 515px) {
  .privacyContainer h1 {
    font-size: 3em;
    text-align: start;
  }
}

.privacyContainer {
  display: flex;
  flex-direction: column;
  padding-top: 8em;
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 5%;
  /* height: 100vh; */
}

.privacyContainer h1 {
  font-size: 3em;
  margin: 0px;
}
.privacyContainer h2 {
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 30px;
}

.privacyContainer p {
  font-size: 1.125rem;
}

.privacyContainer ul li {
  margin: 20px auto;
}
