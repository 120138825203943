.whoPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8em;
  padding-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;
  background-image: url('/src/assets/images/hero-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.whoPage h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 3em;
  font-weight: 600;
  text-align: center;
}

.whoPage h3 {
  margin-top: 0px;
  margin-bottom: 50px;
  font-weight: 400;
}

.whoPage p {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.125em;
}

.personCards {
  display: flex;
}

.personCard {
  flex-basis: 50%;
}

.personCardImg {
  width: 184.3335px;
  height: 200px;
  background: #6a6561;
  background-blend-mode: multiply;
  object-fit: cover;
}

.whoPage a {
  color: black;
}

.container {
  width: 80%;
  height: 80vh;
}

.container iframe {
  width: 100%;
  height: 100%;
}
