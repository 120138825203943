/* .landing-page-card {
  display: flex !important;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 0.6rem;
  padding: 10px;
  margin: 0 auto;
} */

/* helpers */
.app-btn {
  font-family: 'Montserrat';
  width: 10em;
  height: 3em;
  font-size: 1.3em;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 4px;
}

p {
  /* margin: 0px; */
}

/* section 1 */

/* .section1 {
  display: flex;
  justify-content: center;
  height: fit-content;
  padding-top: 8em;
  padding-bottom: 135px;
  gap: 195px;
}

.section1 p {
  font-size: 01.125rem;
  margin: 0px;
}

.section1 button img {
  padding-right: 5px;
  position: relative;
  top: 3px;
}

.text-container {
  max-width: 600px;
  color: #000000;
}

.text-container p {
  padding-bottom: 28px;
}

.text-title {
  font-size: 3em;
}

.event-time {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-time h3 {
  font-size: 2em;
}
.time {
  display: flex;
}

.time > * {
  display: flex;
  padding-right: 10px;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #ffb8bd;
} */

/* section 2 */

.section2 {
  display: flex;
  background-color: #ffffff;
  max-width: 100%;
  align-items: center;
  justify-content: space-around;
  gap: 4%;
  height: 16.875em;
}

.logo-container img {
  width: 100%;
  object-fit: contain;
}

/*section 3*/

.section3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 4em;
  background-color: #ffffff;
}

.section3 h2 {
  font-size: 2.5em;
}

.vid-slides {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #6b6b6c;
}

.vid-viewport {
  width: 50%;
  height: 200px;
  border: 1px solid black;
}
.vid-container {
  position: relative;
  width: fit-content;
}

.vid-container div {
  position: absolute;
}

iframe {
  width: 100%;
}

.slide-btn {
  font-size: 3em;
}

.slide-btn:nth-child(1) {
  margin-right: 10px;
}
.slide-btn:nth-child(3) {
  margin-left: 10px;
}
