/* .landing-page-card {
  display: flex !important;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 0.6rem;
  padding: 10px;
  margin: 0 auto;
} */

/* helpers */
.appBtn {
  font-family: 'Montserrat';

  font-size: 1.3em;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  transition: 0.2s;
}
.appBtn a {
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: black;
}

.appBtn:hover {
  background-color: #9b734e;
  border: 1px solid #9b734e;
}

.appBtn a:hover {
  text-decoration: none;
  color: white;
}

.appBtn a > * {
  fill: black;
}

.appBtn a:hover > * {
  fill: white;
}

/* section 1 */

@media (max-width: 900px) {
  .section1 {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  .section1 h1 {
    text-align: center;
  }

  .section1 p {
    text-align: center;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .textTitle {
    font-size: 3em;
    font-weight: 600;
  }

  .imgContainer {
    width: 100%;
  }

  .imgContainer img {
    object-fit: contain;
    width: 100%;
  }
}

@media (min-width: 900px) {
  .section1 {
    flex-direction: row;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    gap: 100px;
  }

  .textTitle {
    font-size: 3em;
  }
}

.section1 {
  display: flex;
  height: fit-content;
  padding-top: 8em;
  padding-bottom: 50px;
}

.section1 h1 {
  margin: 15px 0px;
}

.section1 p {
  font-size: 01.125rem;
  margin: 0px;
}

.section1 button img {
  padding-right: 5px;
  position: relative;
  top: 2px;
}

.textContainer {
  max-width: 600px;
  color: #000000;
}

.textContainer p {
  padding-bottom: 30px;
}

.eventTime {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventTime h3 {
  font-size: 2em;
}
.time {
  display: flex;
}

.time > * {
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: center;
}

.time div:first-child {
  border-right: 1px solid #ffb8bd;
}

/* section 2 */

.section2 {
  display: flex;
  background-color: #ffffff;
  max-width: 100%;
  align-items: center;
  justify-content: space-around;
  gap: 4%;
  height: 16.875em;
}

.logoContainer img {
  width: 100%;
  object-fit: contain;
}
/*section 3*/

/* .section3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 4em;
  background-color: #ffffff;
}

.section3 h2 {
  font-size: 2.5em;
}

.vid-slides {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #6b6b6c;
}

.vid-viewport {
  width: 50%;
  height: 200px;
  border: 1px solid black;
}
.vid-container {
  position: relative;
  width: fit-content;
}

.vid-container div {
  position: absolute;
}

iframe {
  width: 100%;
}

.slide-btn {
  font-size: 3em;
}

.slide-btn:nth-child(1) {
  margin-right: 10px;
}
.slide-btn:nth-child(3) {
  margin-left: 10px;
} */
