@media (max-width: 900px) {
  .gallery-section h3 {
    text-align: center;
  }

  .gallery-section p {
    text-align: center;
  }

  .gallery-container {
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
  }

  .card {
    width: 100%;
    margin-bottom: 30px;
  }

  .card-text p:first-child {
    font-weight: 600;
  }
}

@media (min-width: 720px) {
  .gallery-container {
    flex-direction: row;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
    transform: translateX(105px);
  }

  .gallery-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 30px;
    transition: width 0.2s;
    position: relative;
  }

  .card:hover {
    width: 400px;
    filter: brightness(100%);
  }

  .card-img {
    filter: brightness(30%);
    transition: filter 0.5s;
  }

  .card-img:hover {
    filter: brightness(100%);
  }

  .card:nth-child(2) {
    z-index: 10;
    right: 16px;
  }
  .card:nth-child(3) {
    z-index: 20;
    right: 32px;
  }
  .card:nth-child(4) {
    z-index: 30;
    right: 46px;
  }
  .card:nth-child(5) {
    z-index: 40;
    right: 62px;
  }
  .card:nth-child(6) {
    z-index: 50;
    right: 76px;
  }
  .card:nth-child(7) {
    z-index: 60;
    right: 88px;
  }
  .card:nth-child(8) {
    z-index: 70;
    right: 102px;
    border-bottom-right-radius: 10px;
  }
  .card:nth-child(9) {
    z-index: 80;
    right: 114px;
    border-bottom-right-radius: 10px;
  }
  .card:nth-child(10) {
    z-index: 90;
    right: 130px;
    border-bottom-right-radius: 10px;
  }
  .card:nth-child(11) {
    z-index: 100;
    right: 144px;
    border-bottom-right-radius: 10px;
  }
  .card:nth-child(12) {
    z-index: 110;
    right: 158px;
    border-bottom-right-radius: 10px;
  }
  .card:nth-child(13) {
    z-index: 120;
    right: 174px;
    border-bottom-right-radius: 10px;
  }
  .card:nth-child(14) {
    z-index: 130;
    right: 186px;
    border-bottom-right-radius: 10px;
  }
  .card:nth-child(15) {
    z-index: 140;
    right: 200px;
    border-bottom-right-radius: 10px;
  }
  .card:nth-child(16) {
    z-index: 150;
    right: 214px;
    border-bottom-right-radius: 10px;
  }
  /* .card:nth-child(17) {
    z-index: 160;
    right: 214px;
    border-bottom-right-radius: 10px;
  }
    .card:nth-child(18) {
    z-index: 170;
    right: 214px;
    border-bottom-right-radius: 10px;
  }
    .card:nth-child(19) {
    z-index: 180;
    right: 214px;
    border-bottom-right-radius: 10px;
  } */
}

@media (min-width: 870px) {
  .card {
    width: 40px;
  }

  .card:hover {
    width: 400px;
  }
}

@media (min-width: 1070px) {
  .card {
    width: 50px;
  }

  .card:hover {
    width: 400px;
  }
}

@media (min-width: 1250px) {
  .card {
    width: 65px;
  }

  .card:hover {
    width: 400px;
  }
}

.gallery-section {
  height: fit-content;
  background-color: #ffffff;
  color: black;
  padding-top: 50px;
  width: 100%;
}

.gallery-section h3 {
  font-size: 2.5rem;
  margin: 0px;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.87);
}

.gallery-container {
  display: flex;

  justify-content: center;
  align-items: center;

  padding-bottom: 50px;
}

.card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: px;
  height: 110px;
  padding-left: 16px;
  box-shadow: -1px 1px 14px 2px rgba(120, 120, 120, 0.4);
}

.card-text:nth-child(1) {
  font-size: 1.125em;
}

.card-text:nth-child(2) {
  font-size: 1em;
}

.card-img {
  height: 342px;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.card-text p {
  transition: font-size 0.1s;
  margin: 0px;
}

.card-text p:nth-child(1) {
  font-size: 1.125em;
  margin-bottom: 8px;
}

.card-text p:nth-child(2) {
  font-size: 0.67em;
}
