@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.MuiContainer-root {
  padding: 0 !important;
  overflow-x: hidden;
}

:root {
  --clr1: rgba(0, 0, 0, 0.87);
  --clr2: rgb(255, 255, 255);
}
