.whoPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8em;
  padding-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;
}

.whoPage h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 3em;
  font-weight: 600;
  text-align: center;
}

.whoPage p {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.125em;
}

.personCards {
  display: flex;
}

.personCard {
  flex-basis: 50%;
}

.personCardImg {
  width: 327.371px;
  height: 399.5px;
  background: #6a6561;
  background-blend-mode: multiply;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.css-1dzhm5y-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}
