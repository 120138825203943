@media (max-width: 515px) {
  .termsContainer {
    text-align: center;
    align-items: center;
  }
  .termsContainer h1 {
    font-size: 3em;
    font-weight: 600;
  }
}

@media (min-width: 515px) {
  .termsContainer h1 {
    font-size: 3em;
    text-align: start;
  }
}

.termsContainer {
  display: flex;
  flex-direction: column;

  padding-top: 8em;
  padding-right: 16px;
  padding-left: 16px;
  margin: 20px auto;
}

.termsContainer h1 {
  margin: 0px;
}
.termsContainer h2 {
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 30px;
}

.termsContainer p {
  font-size: 1.125em;
}
