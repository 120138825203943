.landing-page-card {
  display: flex !important;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 0.6rem;
  padding: 10px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  .footerNav {
    display: none;
  }
}
.whiteLogo {
  width: 150px;
}

@media (min-width: 900px) {
  .footerNav {
    display: flex;
  }
}

.footerNav {
  list-style: none;
  gap: 10px;
  margin: 0px;
  padding: 0px;
}

a {
  display: block;
  text-decoration: none;
  color: white;
  font-size: 1em;
}

a:hover {
  text-decoration: underline;
}
